<template>
    <v-card :loading="loading">
        <v-card-title>
            <v-btn
                class="mr-3"
                depressed
                color="primary"
                icon
                rounded
                @click="close"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>

            INFORM
        </v-card-title>
        <v-card-text>
            <v-form v-model="valid">
                <v-row no-gutters>
                    <v-col cols="6" class="pl-0">
                        <v-select
                            v-model="inform.type"
                            :items="types"
                            label="Type"
                            prepend-icon="mdi-calendar-month-outline"
                            :rules="[rules.required]"
                            :disabled="!createForm"
                        >
                        </v-select>
                    </v-col>
                    <v-col cols="6" class="pl-0">
                        <v-select
                            v-model="inform.report"
                            :items="reports"
                            label="Report"
                            prepend-icon="mdi-calendar-month-outline"
                            :rules="[rules.required]"
                            :disabled="!createForm"
                        >
                        </v-select>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12" class="pl-0">
                        <v-autocomplete
                            multiple
                            v-model="inform.emails"
                            :items="users"
                            item-text="name"
                            item-value="email"
                            label="Emails"
                            prepend-icon="mdi-account"
                            small-chips
                            :rules="[rules.required, rules.emails]"
                        >
                        </v-autocomplete>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col
                        v-if="inform.type == 'weekly'"
                        cols="12"
                        xl="6"
                        lg="6"
                        md="6"
                        class="pl-1"
                    >
                        <v-select
                            v-model="day"
                            :items="days"
                            label="Day *"
                            prepend-icon="mdi-calendar-today"
                            :rules="[rules.required]"
                            required
                        />
                    </v-col>
                    <v-col
                        cols="12"
                        xl="6"
                        lg="6"
                        md="6"
                        :class="
                            `d-flex ${inform.type == 'weekly' ? 'pl-4' : ''}`
                        "
                    >
                        <v-icon>
                            mdi-clock-time-four-outline
                        </v-icon>
                        <div class="ml-2">
                            <p class="mb-0" style="font-size: 12px">Time *</p>
                            <vue-timepicker
                                manual-input
                                v-model="inform.date"
                                input-width="200px"
                                drop-direction="up"
                                style="border: 1px solid #939393"
                            ></vue-timepicker>
                        </div>
                    </v-col>
                </v-row>
                <v-row no-gutters v-if="inform.type == 'weekly'">
                    <v-col cols="12">
                        <h3 class="mt-4 ml-4 mb-4">Targets</h3>
                        <v-data-table
                            :headers="headers"
                            :items="inform.informTargets"
                            class="elevation-0"
                            :mobile-breakpoint="0"
                            disable-pagination
                            hide-default-footer
                        >
                            <!--ITEMS-->
                            <template v-slot:[`item.target`]="{ item }">
                                <v-text-field
                                    v-model="item.target"
                                    placeholder="Target *"
                                    :rule="[rules.required, rules.number]"
                                    @keyup="formatNumber(item)"
                                >
                                </v-text-field>
                            </template>
                            <template v-slot:[`item.unit`]="{ index }">
                                <p class="my-0">
                                    {{ index == 0 ? 'qty' : 'currency' }}
                                </p>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn
                rounded
                color="primary"
                @click="save"
                :loading="loading"
                :disabled="!valid"
            >
                SAVE
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import API from '@/services/api'
import { mapMutations } from 'vuex'
import { objDiff } from '@/helpers/objDiff.js'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'

export default {
    name: 'Informs',
    props: {
        settingId: {
            type: String,
            required: true,
        },
        inform: {
            type: Object,
            default: () => {},
        },
        originalInform: {
            type: Object,
            default: () => {},
        },
        createForm: {
            type: Boolean,
            default: () => false,
        },
    },
    components: {
        VueTimepicker,
    },
    data: () => ({
        reports: ['general', 'followUp'],
        value: '',
        hours2: Array.from({ length: 10 }).map((_, i) => i + 8),
        loading: false,
        users: [],
        valid: false,
        emails: [],
        time: null,
        menu: false,
        hours: '',
        minutes: '',
        rules: {
            required: v => !!v || 'Required',
            emails: v =>
                v.length > 0 ||
                v.find(email => !/.+@.+\..+/.test(email.text)) ||
                'Required',
            email: v => /.+@.+\..+/.test(v) || 'Not a valid E-mail',
            number: v =>
                typeof Number(v.replaceAll('.', '')) == 'number' ||
                'The value is not valid.',
        },
        types: ['daily', 'weekly'],
        days: [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
        ],
        type: undefined,
        day: null,
        informTargets: [
            {
                name: 'New Quotes Requested',
                target: 0,
                unit: 'qty',
            },
            {
                name: 'New Quotes Submitted',
                target: 0,
                unit: 'currency',
            },
            {
                name: 'Modified Quotes Submitted',
                target: 0,
                unit: 'currency',
            },
            {
                name: 'Awarded Quotes',
                target: 0,
                unit: 'currency',
            },
            {
                name: 'Lost Quotes',
                target: 0,
                unit: 'currency',
            },
        ],
        headers: [
            {
                text: 'PARAMS',
                value: 'name',
                align: 'left',
                sortable: false,
            },
            {
                text: 'TARGET',
                value: 'target',
                align: 'center',
                sortable: false,
            },
            {
                text: 'UNIT',
                value: 'unit',
                align: 'center',
                sortable: false,
            },
        ],
    }),
    async mounted() {
        try {
            this.loading = true
            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users
            if (this.inform.day) {
                this.day = this.days[this.inform.day]
            }
            if (!this.inform.informTargets) {
                this.inform.informTargets = this.informTargets
            } else {
                for (let i = 0; i < this.inform.informTargets.length; i++) {
                    let array = []
                    let value = this.inform.informTargets[i].target.toString()
                    for (const j in value) {
                        if (!isNaN(Number(value[j]))) {
                            array.push(value[j])
                        }
                    }
                    let aux = array.join('')
                    const fixNumber = this.format(aux)
                    this.inform.informTargets[i].target = fixNumber
                }
            }
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    computed: {
        informDiff: function() {
            if (!this.createForm) {
                return objDiff(this.originalInform, this.inform)
            } else {
                return null
            }
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        format(n) {
            var formatted = ''
            let count = 0
            for (let i = n.length - 1; i >= 0; i--) {
                if (count === 3) {
                    count = 0
                    formatted = '.' + formatted
                }
                formatted = n[i] + formatted
                count++
            }
            return formatted
        },
        formatNumber(item) {
            let array = []
            for (const i in item.target.toString()) {
                if (!isNaN(Number(item.target[i]))) {
                    array.push(item.target[i])
                }
            }
            let aux = array.join('')
            const fixNumber = this.format(aux)
            item.target = fixNumber
        },
        validateItems(items) {
            for (let item of items) {
                if (
                    item.name.toLowerCase() != 'lost quotes' &&
                    item.name.toLowerCase() != 'lost' &&
                    Number(item.target) <= 0
                ) {
                    return false
                }
            }
            return true
        },
        close() {
            this.$emit('close')
        },
        getMeridiem(time) {
            if (time == '' || time == undefined) {
                return ''
            }
            const hours = parseInt(time.split(':')[0])
            return hours >= 12 ? 'PM' : 'AM'
        },
        async generateInform() {
            try {
                this.loading = true
                this.loadingButton = true
                const inform = await API.createInform({
                    settingId: this.settingId,
                    type: this.inform.type,
                    report: this.inform.report,
                    emails: this.inform.emails,
                    date: this.inform.date.HH + ':' + this.inform.date.mm,
                    informTargets: this.inform.informTargets.map(item => {
                        return {
                            name: item.name,
                            target: Number(
                                `${item.target}`.replaceAll('.', '')
                            ),
                        }
                    }),
                    ...(this.inform.type == 'weekly'
                        ? {
                              day: this.days.findIndex(d => d == this.day),
                          }
                        : {}),
                })
                this.$emit('addInform', inform)
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.loadingButton = false
            }
        },
        async updateInform() {
            try {
                this.loading = true
                this.loadingButton = true
                const paramsToUpdate = {
                    ...this.informDiff,
                    ...(this.informDiff.emails
                        ? {
                              emails: this.inform.emails,
                          }
                        : {}),
                }
                const inform = await API.updateInform({
                    settingId: this.settingId,
                    informId: this.inform.id,
                    ...paramsToUpdate,
                    informTargets: this.inform.informTargets.map(item => {
                        return {
                            name: item.name,
                            target: Number(
                                `${item.target}`.replaceAll('.', '')
                            ),
                        }
                    }),
                    ...(this.inform.type == 'weekly'
                        ? { day: this.days.findIndex(d => d == this.day) }
                        : {}),
                })
                this.$emit('replaceInform', inform)
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.loadingButton = false
            }
        },
        async save() {
            try {
                if (this.createForm) {
                    await this.generateInform()
                } else {
                    await this.updateInform()
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
    },
}
</script>

<style scoped></style>
